import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Login from './routes/login/Login';
import TimeTable from './routes/TimeTable';
import Partners from './routes/Partners';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/login" element={<Login admin={false}/>} />
        <Route path="/userLogin" element={<Login admin={true}/>} />
        <Route path="/idopontok" element={<TimeTable admin={false}/>} />
        <Route path="/adminIdopontok" element={<TimeTable admin={true}/>} />
        <Route path="/adminPartnerek" element={<Partners />}/>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
