import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  Table,
  TableRow,
} from "@mui/material";
import axios from "axios";

import * as Global from '../Global';

const styles = {
  timeInput: {
    backgroundColor: "#FAFAFA",
    borderRadius: 6,
    marginLeft: 8,
    marginRight: 8,
  },
  modifyContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    margin: 8,
    maxWidth: "80%",
    alignSelf: "center",
  }
};

//updating the opening and closing hours
function ModifyTime(props) {
  const [startTime, setStartTime] = useState(props.start);
  const [closeTime, setCloseTime] = useState(props.end);

  const handleSave = async () =>{
    if(startTime > closeTime) {
      alert('A nyitás nem lehet a zárás után');
      return;
    }
    else {
      props.setStart(startTime);
      props.setEnd(closeTime);

      try {
        await axios(Global.apiBaseURL + 'setting/EndOfOpeningHours', {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            dataType: "jsonp",
            Authorization: sessionStorage.getItem("JWTtoken")
          },
          data: {
            type: 'S',
            //value: new Date(0, 0, 1, closeTime.slice(0, 2), closeTime.slice(3, 4)).toISOString()
            value: closeTime
          }
        })

        await axios(Global.apiBaseURL + 'setting/StartOfOpeningHours', {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            dataType: "jsonp",
            Authorization: sessionStorage.getItem("JWTtoken")
          },
          data: {
            type: 'S',
            //value: new Date(0, 0, 1, startTime.slice(0, 2), startTime.slice(3, 4)).toISOString()
            value: startTime
          }
        })
      }
      catch (e) {
        console.log(e);
        alert('A kérés során hiba történt');
      }


      props.getStartTimes();
    }
  }

  return (
    <div style={styles.modifyContainer}>
      <Typography>Nyitvatartás</Typography>
      <TextField style={styles.timeInput} type="time" value={startTime} onChange={(event) => setStartTime(event.target.value)}></TextField>
      <Typography>-tól</Typography>

      <TextField style={styles.timeInput} type="time" value={closeTime} onChange={(event) => setCloseTime(event.target.value)}></TextField>
      <Typography>-ig</Typography>
      <Button
        style={styles.button}
        variant="contained"
        onClick={() => handleSave()}
      >
        Mentés
      </Button>
    </div>
  );
}

//main component
export const TimeManage = () => {
  //states
  const [inputDate, setInputDate] = useState(new Date().toISOString().slice(0, 10));  //input needs special format
  const [allDay,    setAllDay] =    useState(false);                                  //variable for switch
  const [times,     setTimes] =     useState([]);                                     //rendered times
  const [startTime, setStartTime] = useState('06:00');                                //start time
  const [endTime,   setEndTime] =   useState('16:00');                                //end   time
  const [resTimes,  setResTimes] =  useState([]);                                     //reservation data
  
  //render rows, by opening hours - check
  useEffect(() => {
    getReservations(new Date());
  }, []);

  //get the start time and rerender once we have the daily reservations
  useEffect(()=>{
    getStartTimes(new Date(inputDate));
  // eslint-disable-next-line
  },[resTimes]);

  //get the reservations by the day
  const getReservations = async (newDate) => {
    if(isNaN(newDate.getTime())){setTimes([]);return;}
    let response;
    try{
      response = await axios(Global.apiBaseURL + 'reservation/reservations', {
        method:"POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        },
        data:{
          reservationTimestampStart: newDate.toJSON().replace(/T.*/,'T00:00:00.000Z'),
          reservationTimestampEnd: newDate.toJSON().replace(/T.*/,'T23:59:59.999Z')
        }
      });
      setResTimes(response.data.data);
    }
    catch(e){
      console.log(e);
      alert('Nem sikerült lekérni a foglalásokat');
    }
  };

  //store the reservations (or blocked hours)
  const handleSave = async () => {
    let toReg = [];
    let toDel = [];
    for(let i = 0; i < times.length; i++){
      //if newly blocked
      if(times[i].blocked && times[i].id < 0){
        toReg.push({quantity: 1, reservationTimeStamp: times[i].time.toISOString(), pdfSource: 'NA'});
      }
      //if had reservation
      else if(times[i].id > 0){
        let match = resTimes.find(elem => { if(elem.id === times[i].id) return elem; else return null; });
        if(match !== undefined){
          //if partner existed, add to delete
          if(match.partner.id !== 0 && !times[i].partner){
            toDel.push(match.id);
            //if blocked, reg it bacc
            if(times[i].blocked) toReg.push({quantity: 1, reservationTimeStamp: times[i].time.toISOString(), pdfSource: 'NA'});
          }
          //if was blocked but now isnt, add to delete
          else if(Boolean(match.enabled) !== times[i].blocked){
            toDel.push(match.id);
          }
        }
      }
    }

    //firstly remove (because of partner's reservations)
    try{
      if(toDel.length > 0)
      await axios(Global.apiBaseURL + 'reservation/removeReservations', {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        },
        data: {
          ids: toDel
        }
      });

      //then register new ones
      if(toReg.length > 0)
      await axios(Global.apiBaseURL + 'reservation/addReservations', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        },
        data: {
          reservations: toReg
        }
      });

      //kind reminders
      alert('Sikeres változtatások');

      //back to starting point
      setInputDate(new Date().toISOString().slice(0, 10));
      getReservations(new Date());
    }
    catch(e){
      console.log(e);
      alert('Nem sikerült a foglalások megváltoztatása');
    }
  };

  //getting the opening and closing hours
  const getStartTimes = async (newDate = new Date()) => {
    try {
      //get the opening hour
      const {data: startResponse} = await axios(Global.apiBaseURL + 'setting/StartOfOpeningHours', {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        },
      })
      setStartTime(startResponse.data.valueInString);

      //get the closing hour
      const {data: endResponse} = await axios(Global.apiBaseURL + 'setting/EndOfOpeningHours', {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        },
      })
      setEndTime(endResponse.data.valueInString);

      //render btw those 2
      fillTimes(newDate, startResponse.data.valueInString, endResponse.data.valueInString);
    }
    catch (e) {
      console.log(e);
      alert('Nem sikerült lekérni a nytási és zárási időket');
      fillTimes();
    }
  }

  //splitting the date btw the opening hours by 15 mins
  const fillTimes = (newDate = new Date(), start = startTime, end = endTime) => {
    const minutesGap = 30;
    let day = new Date(newDate);
    let dayEnd = new Date(day.getFullYear(), day.getMonth(), day.getDate(), end.slice(0, 2), end.slice(3, 4))

    day.setHours(start.slice(0,2));
    day.setMinutes(start.slice(3,4));

    let tempTimes = [];
    let i = 0; //safety measure
    while (day < dayEnd && i < 1000) {
      //find a match in reservations
      let match = resTimes.find(elem => {
        let dateElem = new Date(elem.reservationTimestamp);
        if(
          dateElem.getHours() === day.getHours()
          && dateElem.getMinutes() === day.getMinutes()        
        ){ return elem;}
        else return null;
      });
      //if theres a reservation
      if(match !== undefined){
        //if theres a partner's reservation
        if(match.partner.id !== 0){
          tempTimes.push({ time: new Date(day), blocked: true , id: match.id, partner: match.partner.name });
        }
        //standard reservation / admin's block
        else{
          tempTimes.push({ time: new Date(day), blocked: true , id: match.id });
        }
      }
      //else render a free day
      else{
        tempTimes.push({ time: new Date(day), blocked: false, id: -1*Math.round(Math.random()*100000) });
      }
      day.setMinutes(day.getMinutes() + minutesGap);
      i++;
    }
    setTimes(tempTimes);
  };

  //filling up <10 strings
  const padTime = (time) => {
    return (
      (time.getHours() < 10 ? "0" : "") +
      time.getHours() +
      ":" +
      (time.getMinutes() < 10 ? "0" : "") +
      time.getMinutes()
    );
  };

  //update row on click
  const onRowClick = (time) => {
    let newTimes = [...times];
    for(let i = 0; i < newTimes.length; i++){
      if(newTimes[i].id === time.id){
        newTimes[i].blocked = !newTimes[i].blocked;
        delete newTimes[i].partner;
        break;
      }
    }
    setTimes(newTimes);
  };

  //block checkbox
  const blockAllDays = (block) => {
    setTimes(
      times.map((time) => ({ time: time.time, blocked: block, id: time.id }))
    );
  };

  return (
    <Accordion
      style={{
        marginLeft: "32px",
        marginRight: "32px",
        marginTop: '16px',
        backgroundColor: "#4DB6AC",
      }}
    >
      <AccordionSummary>
        <Typography>Időpont foglalás beállításai</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>

          <div style={{display: 'flex', flexDirection: 'row'}}>
            
            <TableContainer>
              <Table style={{ maxWidth: 180, backgroundColor: "#FFF" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TextField
                        style={{ backgroundColor: "#FFF", borderRadius: 6 }}
                        type="date"
                        value={inputDate}
                        onChange={(event) => {
                          setInputDate(event.target.value);
                          let d = new Date(event.target.value);
                          getReservations(d);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {times.length > 0 &&
                    times.map((time, id) => (
                      <TableRow
                        style={{ 
                          backgroundColor: 
                            time.blocked ? 
                              time.partner ?  'rgb(243, 249, 70)' : 'rgb(207, 216, 220)'
                              : 'rgb(76, 174, 80)', 
                          textAlign: 'center' 
                        }}
                        key={time.id}
                        onClick={() => onRowClick(time)}
                      >
                        {time.partner} {padTime(time.time)}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    value={allDay}
                    onClick={() => {
                      blockAllDays(!allDay);
                      setAllDay(!allDay);
                    }}
                  />
                }
                label="Teljes nap tiltása"
              />
              <Button
                style={{ margin: 8, maxWidth: "80%", alignSelf: "center" }}
                variant="contained"
                onClick={() => handleSave()}
              >
                Mentés
              </Button>
            </div>
          </div>
          <ModifyTime handleSave={handleSave} setStart={setStartTime} setEnd={setEndTime} start={startTime} end={endTime} getStartTimes={getStartTimes}></ModifyTime>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
