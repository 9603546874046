import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Menu, MenuItem } from '@mui/material';

import engerlogo from '../assets/img/enger-dombor.png';
import hardbit from '../assets/img/logo_white.png';

export default function Header(props) {
  const [location,] = useState(window.location.href.split('/')[window.location.href.split('/').length - 1]);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleLogout = () => {
    sessionStorage.setItem('JWTtoken', null);
    sessionStorage.setItem('userName', null);
    setAnchorEl(null);
    if (location === 'adminIdopontok' || location === 'adminPartnerek') {
      navigate('/userLogin')
    }
    else navigate('/login')
  }

  return (
    <div className="headerbar">
      <img className='header-img' style={{height: '60%', marginLeft: 32}} src={engerlogo} alt="asdasd" />   
      {location !== 'idopontok' &&
        <>
          <Button disabled={location === 'adminPartnerek'} style={{marginLeft: 32, paddingLeft: 25, paddingRight: 25}} variant="contained" onClick={() => navigate('/adminPartnerek')}>Beállítások</Button>    
          <Button disabled={location === 'adminIdopontok'} style={{marginLeft: 32, paddingLeft: 25, paddingRight: 25}} variant="contained" onClick={() => navigate('/adminIdopontok')}>Időpontok</Button>      
        </>
      }   
      {location === 'idopontok' &&
        <>
          <Button style={{marginLeft: 32, paddingLeft: 25, paddingRight: 25}} variant="contained" onClick={() => props.setResetMailing(true)}>Új levelezési cím</Button>
        </>
      }
      <div style={{display: 'grid', justifyItems: 'flex-end'}}>
        <img style={{ width: '15%', marginRight: 32}} src={hardbit} alt="" />
        {/*<p style={{color: '#FFF', marginRight: 32, alignSelf: 'flex-end', marginTop: 5, marginBottom: 5}}>Bejelentkezve: {sessionStorage.getItem('userName')}</p>*/}
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{color: '#FFF', marginRight: 32, alignSelf: 'flex-end'}}
        >
          Bejelentkezve: {sessionStorage.getItem('userName')}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleLogout}>Kijelentkezés</MenuItem>
        </Menu>
      </div>
    </div>
  );
}