import { Link, useNavigate } from "react-router-dom";
import { useEffect } from 'react';

import './App.css';
//import * as Global from './Global';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    if(sessionStorage.getItem("jwtToken") === null){
      navigate('/login');
    }
  // eslint-disable-next-line
  }, [])

  return (
    <div className="App" style={{backgroundColor: '#f5f7fa'}}>
      <Link to="/login">login</Link>
      <Link to="/idopontok">Lista</Link>
    </div>
  );
}

export default App;
