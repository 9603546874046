import {useState} from 'react';
import { Button, TextField, Grid } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import * as Global from '../../Global.jsx';
import './Login.css';
import engerlogo from '../../assets/img/enger-dombor.png';
import hardbit from '../../assets/img/hardbit.jpg';

export default function Login(props) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorText, setErrorText] =useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      let url = '';
      if (props.admin) url = 'userLogin';
      else url = 'partnerLogin';
      console.log(Global.apiBaseURL + 'authentication/' + url)
      const {data: response} = await axios(Global.apiBaseURL + 'authentication/' + url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp"
        },
        data: {
          username: email,
          password: password
        }
      })
      if(response.status === 200) {
        sessionStorage.setItem("JWTtoken", 'Bearer ' + response.data.token);
        sessionStorage.setItem("userName", response.data.loginData.name);
        if (props.admin) navigate('/adminIdopontok');
        if (!props.admin) navigate('/idopontok');
      }
    }
    catch (e) {
      console.log('error volt', e)
      setErrorText('A felhasználónév vagy a jelszó hibás')
    }
  }

  return (
    <main>
      <div className="headerbar"><img className='header-img' style={{height: '60%', marginLeft: 32}} src={engerlogo} alt="asdasd" /></div>
      <p style={{fontSize: 55, textAlign: 'center'}}>Enger időkapu rendszer</p>
      <div style={{display: 'flex', justifyContent: 'center'}}>

        <Grid style={{display: 'flex', flexDirection: 'column', minWidth: 260}}>
          <TextField 
            style={{margin: 8}}
            id="email" 
            label="E-mail" 
            variant="outlined"
            onChange={(event) => {setEmail(event.target.value); setErrorText('') }} 
            error={errorText.length > 0}
            helperText={errorText}
          />
          <TextField 
            style={{margin: 8}}
            id="password" 
            label="Jelszó" 
            variant="outlined" 
            type="password" 
            onChange={(event) => setPassword(event.target.value)} 
          />
          <Button 
            style={{margin: 8, maxWidth: '80%', alignSelf: 'center'}}
            variant="outlined" 
            onClick={() => handleLogin()}
          >Bejelentkezés</Button>
        </Grid>
      </div>
      <div><img style={{position: 'absolute', bottom: 5, right: 32, height: '8%'}} src={hardbit} alt="hardbit logo" /></div>
    </main>
  );
}