import React, {useState, forwardRef} from 'react';
import axios from 'axios';
import {
  TableBody,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Typography,
  TextField,
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mui/material";

import * as Global from "./../Global";

const styles = {
  partnerRow: {
    backgroundColor: "#66BB6A",
    marginLeft: 16,
    marginRight: 16,
    borderRadius: 6,
    border: "1px solid #81C784",
    paddingLeft: 8,
    paddingRight: 8,
  },
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})

const AddPartnerDialog = (props) => {
  const [name, setName] = useState(props.partner.name);
  const [email, setEmail] = useState(props.partner.email);

  const addPartner = async () => {
    try {
      await axios(
        Global.apiBaseURL + "partner/",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            dataType: "jsonp",
            Authorization: sessionStorage.getItem("JWTtoken"),
          },
          data: {
            warehouseId: props.partner.id,
            name: name,
            email: email,
            enabled: props.partner.enabled === 1 ? true : false
          },
        }
      );
      props.setOpen(false);
    }
    catch (e) {
      if(e?.response?.data?.error?.message !== undefined)
        alert(e.response.data.error.message);
      else alert('Nem sikerült a partner regisztrálása');
    }
  }

  return(
    <>
      <DialogTitle>Partner hozzáadása</DialogTitle>
      <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
        <TextField 
          style={{margin: 8}} 
          label="" 
          variant="outlined"
          type="text"
          value={name}
          onChange={(event) => {setName(event.target.value)}} 
        />
        <TextField 
          style={{margin: 8}} 
          label="" 
          variant="outlined"
          type="text"
          value={email}
          onChange={(event) => {setEmail(event.target.value)}} 
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {props.setOpen(false)}}>Mégse</Button>
        <Button onClick={() => addPartner()}>Leadás</Button>
      </DialogActions>
    </>
  )
} 

const WarehousePartners = () => {
  const [partners, setPartners] = useState([]);
  const [search, setSearch] = useState('');
  const [errorText, setErrorText] = useState('');
  const [open, setOpen] = useState(false);
  const [partner, setPartner] = useState({});

  const getPartners = async () => {
    if (search.length < 3) {
      setErrorText('A keresés túl rövid')
    }
    else {
      try {
        const { data: response } = await axios(
          Global.apiBaseURL + "partner/getWarehousePartners",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              dataType: "jsonp",
              Authorization: sessionStorage.getItem("JWTtoken"),
            },
            data: {
              name: search,
            },
          }
        );
        console.log(response)
        setPartners(response.data);
      }
      catch (e) {
        if(e?.response?.data?.error?.message !== undefined)
          alert(e.response.data.error.message);
        else alert('Nem sikerült lekérni a partnereket');
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <Accordion style={{ marginLeft: "32px", marginRight: "32px", backgroundColor: '#4FC3F7'}}>
      <AccordionSummary>
        <Typography>Raktárkezelőben lévő partnerek</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField  
          style={{marginBottom: 8, backgroundColor: '#fff', borderRadius: 4}}
          id="email" 
          label="Keresés" 
          variant="outlined"
          onChange={(event) =>  {setSearch(event.target.value); setErrorText('') }} 
          error={errorText.length > 0}
          helperText={errorText}
        />
        <Button 
            style={{margin: 8, maxWidth: '80%', alignSelf: 'center'}}
            variant="contained" 
            onClick={() => getPartners()}
        >Keresés</Button>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Partner kód</TableCell>
                <TableCell>Név</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell>Engedélyezés</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {partners.map((partner) => (
                <TableRow key={partner.id} style={styles.partnerRow}>
                  <TableCell>{partner.id}</TableCell>
                  <TableCell>{partner.name}</TableCell>
                  <TableCell>{partner.email}</TableCell>
                  <TableCell>
                    <Button 
                      style={{margin: 8, maxWidth: '80%', alignSelf: 'center'}}
                      variant="contained" 
                      onClick={() => {setPartner(partner); setOpen(true)}}
                    >Hozzáadás</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <AddPartnerDialog setOpen={setOpen} partner={partner}/>
      </Dialog>
    </Accordion>
  )
}

export default WarehousePartners;