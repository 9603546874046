import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Switch,
  TableBody,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableHead,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Typography,
  Button,
  Modal,
  Box,
  Grid,
  TextField
} from "@mui/material";

import * as Global from "./../Global";
import Header from "./Header";
import WarehousePartners from "./WarehousePartners";
import { TimeManage } from "./TimeManage";
import { tokenValidation } from "../assets/utils/utils";

const styles = {
  partnerRow: {
    backgroundColor: "#66BB6A",
    marginLeft: 16,
    marginRight: 16,
    borderRadius: 6,
    border: "1px solid #81C784",
    paddingLeft: 8,
    paddingRight: 8,
  },
};

function ActivePartners(props) {
  return (
    <Accordion style={{ marginTop: 0, marginLeft: "32px", marginRight: "32px", marginBottom: "16px", backgroundColor: '#4FC3F7'}}>
      <AccordionSummary>
        <Typography>Aktív partnerek</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Partner kód</TableCell>
                <TableCell>Név</TableCell>
                <TableCell>Bejelentkezési e-mail</TableCell>
                <TableCell>Levelezési cím</TableCell>
                <TableCell style={{textAlign:'center'}}>Engedélyezve</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.partners.map((partner) => (
                <TableRow key={partner.id} style={styles.partnerRow}>
                  <TableCell>{partner.warehouseId}</TableCell>
                  <TableCell>{partner.name}</TableCell>
                  <TableCell>{partner.email}</TableCell>
                  <TableCell>{partner.notificationEmail}</TableCell>
                  <TableCell style={{textAlign:'center'}}>
                    <Switch
                      size="small"
                      value={Boolean(partner.enabled)}
                      defaultChecked={partner.enabled === 1 ? true : false}
                      onChange={() => props.updatePartner(partner)}
                      />
                  </TableCell>
                  <TableCell style={{textAlign:'center'}}>
                    <Button variant="contained" onClick={() => {props.setPartnerId(partner.id);props.setNotiMail(partner.notificationEmail);props.setLoginMail(partner.email);props.setModal(true);}}>
                      Adatok módosítása
                    </Button>
                  </TableCell>
                  <TableCell style={{textAlign:'center'}}>
                    <Button variant="contained" onClick={() => {props.sendResetPassword(partner.id)}}>
                      Jelszó emlékesztő
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

function ActiveUsers(props) {
  return (
    <Accordion style={{ marginTop: 0, marginLeft: "32px", marginRight: "32px", marginBottom: "16px", backgroundColor: '#4FC3F7'}}>
      <AccordionSummary>
        <Typography>Aktív felhasználók</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Felhasználónév</TableCell>
                <TableCell>Név</TableCell>
                <TableCell style={{textAlign:'center'}}>Engedélyezve</TableCell>
                {/* <TableCell style={{textAlign:'center'}}>Törlés</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.users.map((user) => {
                return(
                  <TableRow key={user.id} style={styles.partnerRow}>
                    <TableCell>{user.warehouseUsername}</TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell style={{textAlign:'center'}}>
                      <Switch
                        size="small"
                        value={Boolean(user.enabled)}
                        defaultChecked={user.enabled === 1 ? true : false}
                        onChange={() => props.updateUser(user)}
                      />
                    </TableCell>
                    {/* <TableCell style={{textAlign:'center'}}>
                      <Button variant="contained" onClick={() => {props.deleteUser(user.id)}}>
                        Felhasználó törlése
                      </Button>
                    </TableCell> */}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}




const Partners = () => {
  const [partners,        setPartners] =      useState([]);
  const [users,           setUsers] =         useState([]);
  const [isAdminRegister, setAdminRegister] = useState(false);
  //regform states
  const [userName, setUserName] = useState('');
  const [longName, setLongName] = useState('');
  const [pass,     setPass] =     useState('');
  const [pass2,    setPass2] =    useState('');
  //partner update states
  const [isModal,  setModal] =      useState(false);
  const [partnerId, setPartnerId] = useState(-1);
  const [notiMail, setNotiMail]   = useState('');
  const [loginMail, setLoginMail]   = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    let token = sessionStorage.getItem("JWTtoken");
    let tokenValidResult = tokenValidation(token);
    if(tokenValidResult.isValid){
      getPartners();
      getUsers();
    }
    else{
      navigate(tokenValidResult.goTo);
    }    
  // eslint-disable-next-line
  }, []);

  const getUsers = async () => {
    try {
      const { data: response } = await axios(
        Global.apiBaseURL + "user/getUsers",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            dataType: "jsonp",
            Authorization: sessionStorage.getItem("JWTtoken"),
          },
          data: {},
        }
      );
      setUsers(response.data);
    } catch (e) {
      if(e?.response?.data?.error?.message !== undefined)
        alert(e.response.data.error.message);
      else alert('Nem sikerült lekérni a felhasználokat');
    } 
  }

  const getPartners = async () => {
    try {
      const { data: response } = await axios(
        Global.apiBaseURL + "partner/getPartners",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            dataType: "jsonp",
            Authorization: sessionStorage.getItem("JWTtoken"),
          },
          data: {},
        }
      );
      setPartners(response.data);
    } catch (e) {
      if(e?.response?.data?.error?.message !== undefined)
        alert(e.response.data.error.message);
      else alert('Nem sikerült lekérni a partnereket');
    } 
  };

  const updateNotiMail = async () => {
    //sending only the changes
    let data = {};
    let partner = partners.find(partner => partner.id === partnerId);
    if(partner.email !== loginMail) data.email = loginMail;
    if(partner.notificationEmail !== notiMail) data.notificationEmail = notiMail;

    //preventing the request without changes
    if(Object.keys(data).length === 0){alert('Nem történt változás'); return;}

    try{
      await axios(Global.apiBaseURL + "partner/"+partnerId, {
        method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            dataType: "jsonp",
            Authorization: sessionStorage.getItem("JWTtoken"),
          },
          data: {
            ...data
          },
      });
      setModal(false);
      getPartners();
    }
    catch(e){
      if(e?.response?.data?.error?.message !== undefined)
        alert(e.response.data.error.message);
      else alert('Nem sikerült megváltoztatni a partnert');
    }
  }

  const updatePartner = async (partner) => {
    try {
      await axios(
        Global.apiBaseURL + "partner/" + partner.id,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            dataType: "jsonp",
            Authorization: sessionStorage.getItem("JWTtoken"),
          },
          data: {
            enabled: partner.enabled === 1 ? false : true,
          },
        }
      );
      getPartners();
    } catch (e) {
      if(e?.response?.data?.error?.message !== undefined)
        alert(e.response.data.error.message);
      else alert('Nem sikerült frissíteni a partnereket');
    } 
  };

  const updateUser = async (user) => {
    try {
      await axios(
        Global.apiBaseURL + "user/" + user.id,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            dataType: "jsonp",
            Authorization: sessionStorage.getItem("JWTtoken"),
          },
          data: {
            enabled: user.enabled === 1 ? false : true,
          },
        }
      );
      getUsers();
    } catch (e) {
      if(e?.response?.data?.error?.message !== undefined)
        alert(e.response.data.error.message);
      else alert('Nem sikerült frissíteni a felhasználót');
    } 
  };

  const deletePartner = async (id) => {
    try {
      await axios(
        Global.apiBaseURL + "partner/" + id,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            dataType: "jsonp",
            Authorization: sessionStorage.getItem("JWTtoken"),
          },
          data: {},
        }
      );
      getPartners();
    } catch (e) {
      if(e?.response?.data?.error?.message !== undefined)
        alert(e.response.data.error.message);
      else alert('Nem sikerült a partner törlése');
    } 
  };

  const deleteUser = async (id) => {
    try {
      await axios(
        Global.apiBaseURL + "user/" + id,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            dataType: "jsonp",
            Authorization: sessionStorage.getItem("JWTtoken"),
          },
          data: {},
        }
      );
      getUsers();
    } catch (e) {
      if(e?.response?.data?.error?.message !== undefined)
        alert(e.response.data.error.message);
      else alert('Nem sikerült a felhasználó törlése');
    } 
  };

  const sendResetPassword = async (id) => {
    try{
      await axios(Global.apiBaseURL+'partner/resetPassword/'+id, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken"),
        },
      });
      alert('Elküldtük a jelszó emlékeztetőt');
    }
    catch(e){
      if(e?.response?.data?.error?.message !== undefined)
        alert(e.response.data.error.message);
      else alert('Nem sikerült elküldeni a jelszó emlékeztetőt');
    }
  }

  //registering a new admin
  const registerUser = async () => {
    //guards
    if(userName.length < 3){alert('A felhasználó név legalább 3 karakterből kell álljon');return;}
    if(longName.length < 3){alert('A teljes név legalább 3 karakterből kell álljon');return;}
    if(userName.length > 10){alert('A felhasználó név nem lehet hosszabb, mint 10 karakter');return;}
    if(longName.length > 200){alert('A teljes név nem lehet hosszabb, mint 200 karakter');return;}
    if(pass !== pass2){alert('A két jelszó nem egyezik meg');return;}
    if(pass.length < 7){alert('A jelszó legalább 7 karakter hosszú kell legyen');return;}
    if(pass === pass.toLowerCase()){alert('A jelszónak tartalmaznia kell egy nagy betűt');return;}
    if(pass === pass.toUpperCase()){alert('A jelszónak tartalmaznia kell egy kis betűt');return;}
    if(!(/\d/.test(pass))){alert('A jelszónak tartalmazni kell egy számot');return;}
    
    //sending the request
    try{
      await axios(Global.apiBaseURL + "user",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            dataType: "jsonp",
            Authorization: sessionStorage.getItem("JWTtoken"),
          },
          data: {
            warehouseUsername: userName,
            name: longName,
            passwd: pass,
            enabled: false
          },
        }
      );
      getUsers();
      setAdminRegister(false);
    } catch (e) {
      if(e?.response?.data?.error?.message !== undefined)
        alert(e.response.data.error.message);
      else alert('Nem sikerült regisztrálni');
    }    
  };

  return (
    <>
      <Header />
      <Button
        style={{ margin: '32px 32px 16px', maxWidth: "80%", alignSelf: "center" }}
        variant="contained"
        onClick={() => setAdminRegister(true)}
      >
        Új admin felhasználó regisztrálása
      </Button>
      <ActivePartners
        sendResetPassword={sendResetPassword}
        setPartnerId={setPartnerId}
        setNotiMail={setNotiMail}
        setLoginMail={setLoginMail}
        partners={partners}
        setModal={setModal}
        updatePartner={updatePartner}
        deletePartner={deletePartner}
      />
      <ActiveUsers
        users={users}
        updateUser={updateUser}
        deleteUser={deleteUser}
      />
      <WarehousePartners />
      <TimeManage />
      <Modal 
        open={isModal}
        onClose={()=>setAdminRegister(false)}
        style={{display:'flex',justifyContent:'center', alignItems:'center'}}
      >
        <Box sx={{ border:'black 4px solid',maxWidth: 900, width: '80%', display:'flex',backgroundColor:'white', height: 650, flexDirection:'column', justifyContent:'space-evenly' }}>
          <Typography textAlign="center" variant="h3">Partner adatainak módosítása</Typography>
          <Grid container spacing={4}>
            <Grid item xs={6} style={{alignItems:'flex-end',display:'flex',justifyContent:'center', flexDirection:'column'}}>
              <Typography variant="h4" textAlign="right">Bejelentkezési e-mail *</Typography>
              <Typography style={{fontSize:'0.8rem'}} textAlign="right">* ezzel a jelszó is változni fog</Typography>
            </Grid>
            <Grid item xs={6} style={{alignItems:'center',display:'flex',justifyContent:'flex-start'}}>
              <div style={{display:'flex',alignItems:'center'}}>
                <TextField defaultValue={loginMail} style={{width:250}} onChange={(e)=>setLoginMail(e.target.value)}/>
              </div>
            </Grid>
            <Grid item xs={6} style={{alignItems:'center',display:'flex',justifyContent:'flex-end'}}>
              <Typography variant="h4" textAlign="right">Levelezési cím</Typography>
            </Grid>
            <Grid item xs={6} style={{alignItems:'center',display:'flex',justifyContent:'flex-start'}}>
              <div style={{display:'flex',alignItems:'center'}}>
                <TextField defaultValue={notiMail} style={{width:250}} onChange={(e)=>setNotiMail(e.target.value)}/>
              </div>
            </Grid>
          </Grid>
          <div style={{display:'flex',flexDirection:'column'}}>
            <Button
              style={{ maxWidth: "80%", alignSelf: "center", backgroundColor:'darkcyan', marginBottom:32 }}
              variant="contained"
              onClick={()=>updateNotiMail()}
            >
              Mentés
            </Button>
            <Button
              style={{ maxWidth: "80%", alignSelf: "center", backgroundColor:'red' }}
              variant="contained"
              onClick={()=>setModal(false)}
            >
              Bezárás
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={isAdminRegister}
        onClose={()=>setAdminRegister(false)}
        style={{display:'flex',justifyContent:'center', alignItems:'center'}}
      >
        <Box sx={{ maxWidth: 800, width: '80%', display:'flex',backgroundColor:'white', height: 650, flexDirection:'column' }}>
          <Typography variant="h4" textAlign='center' style={{marginTop:32,marginBottom:48}}>
            Adja meg az új felhasználó adatait
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={6} justifyContent='flex-end' style={{alignItems:'center', display:'flex'}}>
              <Typography textAlign='right'>Felhasználó név:</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField style={{width:250}} onChange={(e)=>setUserName(e.target.value)}/>
            </Grid>
            <Grid item xs={6} justifyContent='flex-end' style={{alignItems:'center', display:'flex'}}>
              <Typography textAlign='right'>Teljes név:</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField style={{width:250}} onChange={(e)=>setLongName(e.target.value)}/>
            </Grid>
            <Grid item xs={6} justifyContent='flex-end' style={{alignItems:'center', display:'flex'}}>
              <Typography textAlign='right'>Jelszó:</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField 
                style={{width:250}}
                onChange={(e)=>setPass(e.target.value)} 
                type="password"
                helperText="Legalább 7 karakter, kisbetű, nagybetű és számot tartalmazva"
              />
            </Grid>
            <Grid item xs={6} justifyContent='flex-end' style={{alignItems:'center', display:'flex'}}>
              <Typography textAlign='right'>Jelszó mégegyszer:</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField style={{width:250}} onChange={(e)=>setPass2(e.target.value)} type="password"/>
            </Grid>
          </Grid>
          <Button
            style={{ margin: '32px 32px 16px', maxWidth: "80%", alignSelf: "center" }}
            variant="contained"
            onClick={registerUser}
          >
            Regisztráció
          </Button>
          <Button
            style={{ maxWidth: "80%", alignSelf: "center", backgroundColor:'red' }}
            variant="contained"
            onClick={()=>setAdminRegister(false)}
          >
            Bezárás
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Partners;
