import { useState } from 'react';
import axios from 'axios';
import {  TextField, Button, DialogActions, DialogContent, DialogTitle, Link } from "@mui/material";

import * as Global from '../Global'
import LoadingOverlay from 'react-loading-overlay';

const ReservationDialog = (props) => {
  const [isLoading, setLoading] = useState(false);
  const [file, setFile] = useState();
  const [pallet, setPallet] = useState(props.timeStamp?.pallets);
  const [disabled] = useState((props.timeStamp.id === undefined ?  false : true));

  const handleReserve = async () => {
    if (file === undefined) {
      alert('Kérem adjon meg fájlt! Az időpont nem lett lefoglalva.');
      return;
    }
    
    if (props.timeStamp.id !== undefined) {
      handleUpdate();
      return;
    };

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      reserveCommunication(reader.result.substr(reader.result.indexOf(',') + 1))
    }
    reader.onerror = function (error) {
      console.log(error);
    }
  }

  const reserveCommunication = async (content) => {
    if(pallet === undefined || pallet < 0){alert('A rekalpoknak pozitívnak kell lennie');return;}
    if(content.length === 0){alert('Nem lehet üres fájlt feltölteni');return;}
    try {
      setLoading(true);
      await axios(Global.apiBaseURL + 'reservation/', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        },
        data: {
          //file: fileContent,
          quantity: pallet,
          reservationTimeStamp: props.timeStamp.timeStamp.toISOString(),
          //pdfSource: file.name + props.timeStamp.timeStamp.toISOString(),
          pdfSource: content,
        }
      });
      props.setOpen(false);
      props.getTimes();
      setLoading(false);
    }
    catch (e) {
      console.log(e);
      alert('A rögzítés során hiba történt, próbáld újra később');
      setLoading(false);
    }
  }

  const handleUpdate = async () => {
    try {
      const {data: response} = await axios(Global.apiBaseURL + 'reservation/' + props.timeStamp.id, {
        method: 'PUT',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        },
        data: {
          file: file,
          quantity: pallet,
          reservationTimeStamp: props.timeStamp.timeStamp.toISOString(),
          pdfSource: file.name + props.timeStamp.timeStamp.toISOString(),
        }
      })
      if (response.status === 200) {
        props.setOpen(false);
        props.getTimes();
      }
    }
    catch (e) {
      console.log(e);
    } 
  }

  const handleDelete = async () => {
    try {  
      if(props.timeStamp.timeStamp.getTime() < new Date( new Date().setHours(new Date().getHours() + 3)).getTime()) {
        alert('3 órán belüli szállítást nem lehet törörlni');
      }
      const {data: response} = await axios(Global.apiBaseURL + 'reservation/' + props.timeStamp.id, {
        method: 'DELETE',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        }
      })
      if (response.status === 200) {
        props.setOpen(false);
        props.getTimes();
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Fájl feltöltése'
    >
      <div style={{width: 450}}>
        <DialogTitle>Beszállítási idő foglalása</DialogTitle>
        <DialogTitle style={{margin: 0, paddingTop: 0}}>{props.timeStamp.timeStamp.toISOString().slice(0, 10) + ' ' + (props.timeStamp.timeStamp.getHours() < 10 ? '0' : '') + props.timeStamp.timeStamp.getHours() 
              + ':' + (props.timeStamp.timeStamp.getMinutes() < 10 ? '0' : '') + props.timeStamp.timeStamp.getMinutes()}</DialogTitle>
        <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
          <TextField 
            style={{margin: 8, maxWidth: 250, alignSelf: 'center'}} 
            label="Raklapok száma" 
            variant="outlined"
            type="number"
            value={pallet}
            onChange={(event) => {setPallet(event.target.value)}} 
            disabled={disabled}
            //error={errorText.length > 0}
            //helperText={errorText}
          />
          {file?.name !== undefined &&
            <p style={{maxWidth: 400}}>Kiválasztott fájl: {file.name}</p>
          }
          {disabled ?
            <Button
              variant="contained"
              component="label"
              style={{maxWidth: 250, alignSelf: 'center'}}
            >
              <Link rel="noopener" target="_blank" underline="none" style={{color: '#FFF'}} href={Global.fileURL + props.timeStamp.pdfSource}>PDF megtekintése</Link>
            </Button>
            :
              <Button
                variant="contained"
                component="label"
                disabled={disabled}
                style={{maxWidth: 250, alignSelf: 'center'}}
                >
                PDF feltöltése
                <input
                  type="file"
                  hidden
                  accept=".pdf"
                  onChange={(event) => {setFile(event.target.files[0])}}
                  />
              </Button>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {props.setOpen(false)}}>Mégse</Button>
          {props.timeStamp.id &&
            <Button onClick={handleDelete}>Törlés</Button>
          }
          <Button onClick={handleReserve} disabled={disabled}>Leadás</Button>
        </DialogActions>
      </div>
    </LoadingOverlay>
  )
}

export default ReservationDialog;