import { useState, useEffect, forwardRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TableContainer, TableRow, Paper, Table, TableCell, TableBody, Accordion, AccordionSummary, Typography, AccordionDetails, Dialog, Slide, Box, TextField, Button } from "@mui/material";

import * as Global from '../Global';
import ReservationDialog from "./ReservationDialog";
import Header from "./Header";
import { tokenValidation } from "../assets/utils/utils";

const style = {
  border: '4px solid rgba(236, 239, 241, 1)',
  borderTop: 0,
  borderBottom: '1px solid rgba(207, 216, 220, 0.5)',
  //border: '4px solid "#CFD8DC"',
  display: "block", 
  padding: 0,
  textAlign: 'center',
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})


const fillDays = (times, day, end) => {
  //minutes btw each row
  const minuteGap = 30;

  //array of the results
  let dayTimes = [];

  //filter only to today's reservations
  times = times.filter(time => day <= new Date(time.reservationTimestamp) && new Date(time.reservationTimestamp) <= end);

  //just a guard
  let i = 0;

  //loop through the times
  while(day < end && i < 1000){

    //find the reservation for this moment
    let time = times.find(time => new Date(time.reservationTimestamp).getTime() === day.getTime());
    i++;

    //if it exists, add with data
    if(time !== undefined){
      dayTimes.push({
        timeStamp: new Date(time.reservationTimestamp), 
        reserved: true, 
        quantity: time.quantity, 
        id: time.id, 
        partner: time.partner?.name, 
        pdfSource: time.pdfSource,
        partnerId: time.partnerId
      });
      day.setMinutes(day.getMinutes() + minuteGap);
    }
    //otherwise add with empty
    else{
      dayTimes.push({timeStamp: new Date(day), reserved: false})
      day.setMinutes(day.getMinutes() + minuteGap);
    }
  }
  return(dayTimes)
}

const TimesInDay = (props) => {

  const handleOpen = (time) => {
    if(props.admin)return;
    //TODO: itt a miki küldeni fog egy tömböt, arra rá kell majd nézegetni hogy abban benne vagyon e.
    if (props.specialDays.some(day => new Date(day.specialDate).toDateString() === time.timeStamp.toDateString() && ['S', 'U'].includes(day.type))){
      return;
    }
    if ([6, 0].includes(time.timeStamp.getDay())  && !props.specialDays.some(day => new Date(day.specialDate).toDateString() === time.timeStamp.toDateString() && day.type==='M')) {  
      return;
    }
    else if (time.reserved && time.quantity !== null) { //foglalás van az időpontra, amit láthatunk
      props.setOpen(true);
      props.setTimeStamp({id: time.id, timeStamp: time.timeStamp, pallets: time.quantity, pdfSource: time.pdfSource });
    }
    else if (new Date(time.timeStamp).getTime() < new Date( new Date().setHours(new Date().getHours() + 3)).getTime()) {  //minimum 3 óra múlva lehet leadni
      return;
    }
    else if (time.reserved && time.quantity === null) { //foglalás van az időpontra amit nem láthatunk
      return;
    }
    else {  //üres az időpont
      if (props.admin) {  //admin üresre nem megy
        return;
      }
      else {  //felhasználó új időt kér
        props.setOpen(true);
        props.setTimeStamp({timeStamp: time.timeStamp});
      }
    }
  }

  let day = props.day;
  day = new Date(day.getFullYear(), day.getMonth(), day.getDate(), props.start.slice(0,2), props.start.slice(3,4));
  let dayEnd = new Date(day.getFullYear(), day.getMonth(), day.getDate(), props.end.slice(0,2), props.end.slice(3,4));
  return(
    <TableRow style={{display: "table-cell"}}>
      <TableCell style={{display: "block", textAlign: "center", padding: 10}}>{day.toISOString().slice(0, 10)}</TableCell>
      <TableCell style={{display: "block", textAlign: "center", padding: 10}}>{day.toLocaleDateString("hu-HU", {weekday: 'long'})}</TableCell>
      {fillDays(props.times, day, dayEnd).map((time, id) => (
        <TableCell 
          size="small"
          style={{...style, backgroundColor: setBackround(time, props.specialDays)}} 
          key={id}
          onClick={()  => {handleOpen(time)}} >
           {padTime(time, props.admin)}
        </TableCell>
      ))}
    </TableRow>
  )
}

const setBackround = (time, specialDays) => {
  let currentTime = new Date( new Date().setHours(new Date().getHours() + 3));
  //S: szabadnap
  //M: munkanap
  //U: ünnepnap

  if(specialDays.some(day => new Date(day.specialDate).toDateString() === time.timeStamp.toDateString() && ['S', 'U'].includes(day.type))){
    return '#CFD8DC';
  }

  if ( [6, 0].includes(time.timeStamp.getDay()) && !specialDays.some(day => new Date(day.specialDate).toDateString() === time.timeStamp.toDateString() && day.type === 'M')) {
    return '#CFD8DC';
  }
  else if (time.partnerId === 0){
    return '#CFD8DC';
  }
  if (time.reserved && time.quantity !== null) {
    return '#FFF176';
  }
  else if (new Date(time.timeStamp).getTime() < currentTime.getTime()) {
    return '#CFD8DC';
  }
  else if (time.reserved && time.quantity === null) {
    return '#D32F2F'
  }
  else return '#4CAF50'
}

const padTime = (time, admin) => {
  if (time.quantity === undefined || time.quantity === null || time.partnerId === 0) {
    time = time.timeStamp;
    return (time.getHours() < 10 ? '0' : '') + time.getHours() + ':' + (time.getMinutes() < 10 ? '0' : '') + time.getMinutes();
  }
  else {
    let quantity = time.quantity;
    let partner = time.partner;
    time = time.timeStamp;
    if (admin) {
      return(
        partner + '  ' + (time.getHours() < 10 ? '0' : '') + time.getHours() + ':' + (time.getMinutes() < 10 ? '0' : '') + time.getMinutes() + '  ' + quantity + ' raklap'
      )
    }
    else return (time.getHours() < 10 ? '0' : '') + time.getHours() + ':' + (time.getMinutes() < 10 ? '0' : '') + time.getMinutes() + '  ' + quantity + ' raklap'
  }
}

const MapWeek = (props) => {
  let week = props.week;
  return(
    <Accordion style={{marginTop: 8, marginLeft: 32, marginRight: 32, marginBottom: 8, backgroundColor: props.backgroundColor}}>
      <AccordionSummary>
        <Typography>{week[0].toISOString().slice(0, 10)} - tól - {week[week.length - 1].toISOString().slice(0, 10)} - ig</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component = {Paper} >
          <Table style={{minWidth: 1150}} >
            <TableBody>
              {week.map((day, id) => (
                <TimesInDay key={id}  day={day} {...props}/>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  )
}

export default function TimeTable(props) {
  const [week1, setWeek1] = useState([]);
  const [week2, setWeek2] = useState([]);
  const [week3, setWeek3] = useState([]);
  const [week4, setWeek4] = useState([]);
  const [times, setTimes] = useState([]);
  const [isResetMailing, setResetMailing] = useState(false);
  const [mailingAddress, setMailingAddress] = useState('');
  const [specialDays, setSpecialDays] = useState([]);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [open, setOpen] = useState(false);
  const [timeStamp, setTimeStamp] = useState({});
  const navigate = useNavigate();
 
  useEffect(() => {
    let token = sessionStorage.getItem("JWTtoken");
    if(token === null) {
      navigate('/login');
    }
    else {
      let tokenValidResult = tokenValidation(token);
      if(tokenValidResult.isValid){
        getTimes();
        getMailingAddress();
      }
      else{
        navigate(tokenValidResult.goTo);
      }
    }
  // eslint-disable-next-line
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const saveMailingAddress = async () => {
    if(mailingAddress.length > 100){alert('A levelezési cím nem lehet hosszabb, mint 100 karakter');return;}
    try{
      await axios(Global.apiBaseURL + 'partner', {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        },
        data:{
          notificationEmail: mailingAddress
        }
      });
      setResetMailing(false);
      getMailingAddress();
    }
    catch(e){
      if(e?.response?.data?.error?.message !== undefined)
        alert(e.response.data.error.message);
      else alert('Nem sikerült megváltoztatni a levelezést');
    }
  }

  const getMailingAddress = async () => {
    //return when admin
    if(props.admin)return;
    let response;
    try{
      response = await axios(Global.apiBaseURL + 'partner', {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        },
      });
      setMailingAddress(response.data.data.notificationEmail);
    }
    catch(e){
      if(e?.response?.data?.error?.message !== undefined)
        alert(e.response.data.error.message);
      else alert('Nem sikerült lekérni a levelezési címet');
    }
  }

  const getTimes = async () => {

    let curr = new Date(); 
    let plus1 = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() + 7, 10);
    let plus2 = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() + 14, 10);
    let plus3 = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() + 21, 10);
    let week = [];
    let week2 = [];
    let week3 = [];
    let week4 = [];

    for (let i = 1; i <= 7; i++) {
      let first = curr.getDate() - curr.getDay() + i;
      let day = new Date(curr.setDate(first));
      week.push(day);

      let plus1First = plus1.getDate() - plus1.getDay() + i;
      let plus1Day = new Date(plus1.setDate(plus1First));
      week2.push(plus1Day);

      
      let plus2First = plus2.getDate() - plus2.getDay() + i;
      let plus2Day = new Date(plus2.setDate(plus2First));
      week3.push(plus2Day);

      
      let plus3First = plus3.getDate() - plus3.getDay() + i;
      let plus3Day = new Date(plus3.setDate(plus3First));
      week4.push(plus3Day);
    }

    setWeek1(week);
    setWeek2(week2);
    setWeek3(week3);
    setWeek4(week4);
    try {
      
      const {data: specialdays} = await axios(Global.apiBaseURL + 'specialday/getSpecialDays', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        },
        data: {
          filler: ''
        }
      })
      setSpecialDays(specialdays.data);
      const {data: startTime} = await axios(Global.apiBaseURL + 'setting/StartOfOpeningHours', {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        },
      })
      setStartTime(startTime.data.valueInString)
      const {data: endTime} = await axios(Global.apiBaseURL + 'setting/EndOfOpeningHours', {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        },
      })
      setEndTime(endTime.data.valueInString)


      let url = '';
      if (props.admin) url = '/reservations';
      const {data: times} = await axios(Global.apiBaseURL + 'reservation' + url, {
        method: props.admin ? "POST" : "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          dataType: "jsonp",
          Authorization: sessionStorage.getItem("JWTtoken")
        },
        data:{}
      })
      if(times.data.length === 0) { //ha üres, akkor nem fog megtörténni a megjelenítés
        setTimes([{
          "partnerId": null,
          "partner": null,
          "reservationTimestamp": "1970-04-10T12:00:00.000Z",
          "quantity": null,
          "pdfSource": null,
          "enabled": 1,
          "id": 1,
          "createdAt": "1970-04-26T08:55:48.714Z",
          "updatedAt": "1970-04-26T08:55:48.714Z"
        }])
      }
      else setTimes(times.data);

      
    }
    catch (e) {
      console.log(e)
      if (e.response?.status === 401) {
        alert('Az ön bejelentkezése lejárt, vagy ön nem jogosult erre a műveletre');
        if (!props.admin) navigate('/login');
        if (props.admin) navigate('/userLogin');
      }
      else alert('A kérés során hiba történt. Kérem próbálja újra');
    }
    
      
  }

  

  return (
    <main style={{ backgroundColor: '#f5f7fa' }}>
      <Header setResetMailing={setResetMailing}/>
      
      {times.length > 0 && 
        <>
          <MapWeek week={week1} times={times} setOpen={setOpen} setTimeStamp={setTimeStamp} admin={props.admin} backgroundColor={'#8BC34A'} start={startTime} end={endTime} specialDays={specialDays}/>
          <MapWeek week={week2} times={times} setOpen={setOpen} setTimeStamp={setTimeStamp} admin={props.admin} backgroundColor={'#CDDC39'} start={startTime} end={endTime} specialDays={specialDays}/>
          <MapWeek week={week3} times={times} setOpen={setOpen} setTimeStamp={setTimeStamp} admin={props.admin} backgroundColor={'#FFEB3B'} start={startTime} end={endTime} specialDays={specialDays}/>
          <MapWeek week={week4} times={times} setOpen={setOpen} setTimeStamp={setTimeStamp} admin={props.admin} backgroundColor={'#FFC107'} start={startTime} end={endTime} specialDays={specialDays}/>
        </>
      }
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{minWidth: 500}}
      >
        <ReservationDialog admin={props.admin} setOpen={setOpen} timeStamp={timeStamp} getTimes={getTimes}/>
      </Dialog>
      <Dialog
        open={isResetMailing}
        onClose={()=>setResetMailing(false)}
        aria-describedby="alert-dialog-slide-description"
        style={{minWidth: 500}}
        TransitionComponent={Transition}
      >
        <Box style={{minWidth:600, minHeight:600, display:'flex', justifyContent:'space-evenly', flexDirection:'column', alignItems:'center'}}>
          <h2>Új levelezési cím megadása</h2>
          <TextField label="A levelezési cím" defaultValue={mailingAddress} style={{width:250}} onChange={(e)=>setMailingAddress(e.target.value)}/>
          <div>
            <Button style={{margin:16}} onClick={() => {setResetMailing(false)}}>Mégse</Button>
            <Button style={{margin:16}} onClick={() => {saveMailingAddress()}}>Mentés</Button>
          </div>
        </Box>
      </Dialog>
    </main>
  );
}